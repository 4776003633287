import styled from 'styled-components';

const Category = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-text-2);
  padding: 2px 6px;
  background-color: var(--color-tag-background);
  border-radius: 6px;
`;

export default Category;
